@value large, medium, small, xsm from './Breakpoints.module.css';



h5 {
	font-family: 'Druk-Text-Web';
	margin: 0 0 5px 0;
	text-transform: uppercase;
	font-weight: 500;
	font-size: 14px;
	margin-bottom: 5px;
	column-span: all;
}




h1 {
	letter-spacing: 0.03em;
	font-family: 'Druk-Web', sans-serif;
	text-transform: uppercase;
	font-size: 36px;
	text-transform: uppercase;
	margin: 0;
	margin-bottom: 0.7rem;
}


h2, h1 {
	line-height: 0.95;
	font-weight: 500;

}


h2 {
	margin: 0 0 1rem 0;
	font-family: 'Druk-Text-Web', sans-serif;
	font-weight: 500;
	font-size: 24px;
	letter-spacing: -0.02em;
}

p {
	font-weight: normal;
	line-height: 1.35;
	font-size: 20px;
	margin: 0;
	-webkit-font-smoothing: initial;
}


h3 {
	font-family: 'Druk-Text-Web', sans-serif;
	font-weight: 500;
	font-size: 16px;
	margin: 0;
}



em {
	font-style: italic;
	font-weight: 500;
}



@media (xsm){
	h2 {
		font-size: min(2.4vw, 34px);
	}
	h1 {	
		font-size: min(3.4vw, 50px);	
	}
}



