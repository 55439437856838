@value large, medium, small, xsm from './../Breakpoints.module.css';

.map {
	
	height: 100%;
	
	
	position: relative;
	--article-width: calc(100% - 30px);
	--max-width: none;
	margin-left: 30px;
	width: var(--article-width);
}
.mapInner {
	
	position: absolute;
	top: 0;
	z-index: 0	;
	bottom: 0;
	right: 0;
	left: 0;
}
@-moz-document url-prefix() {
	.map {
		max-height: 8192px;		
	}
}



.article {
	position: relative;
	z-index: 1;
	display: block;
	grid-gap: 0;
	
	border-top: 1px solid var(--orange);
	padding: 20px;

	grid-template-columns: var(--article-width);
	max-width: var(--max-width);
	width: 100%;
}

.ships-and-souvenirs {
	

	left: var(--padding);
}
.ships-and-souvenirs p {
	display: -webkit-box;
	-webkit-line-clamp: 15;
	-webkit-box-orient: vertical;  
	overflow: hidden;
}

.imageContainer {
	position: relative;
}

.the-river-seemed-almost-turned-to-blood {
	left: var(--padding);
	
	/* margin-top: -500px; */
}



.whistler-and-battersea {
	
	display: block;
	right: 0;
	padding-right: var(--padding);
	
}

.circleTitle {
	text-transform: uppercase;
	color: var(--orange);
	top: -18px;
	text-align: right;
	position: absolute;
	font-size: 11px;
	right: 0;
	font-weight: bold;
	padding-right: 10px;
}




.the-surrounding-great-work .circleTitle {
	text-align: left;
	left: 30px;
}

.the-surrounding-great-work {

}

.galleryImage {
	
}
.galleryImage img {
	margin-bottom: 1rem;
	width: 100%;
	display: block;

}

.article h2 {
	
}

.caption {
	font-size: 12px;
	margin-bottom: 1rem;
	
}

@media only screen and (max-width: 767px){
	.article {
		left: auto !important;
		top: auto !important;
	}
}
@media (xsm){
	.the-river-seemed-almost-turned-to-blood .imageContainer,
	.women-in-whistler .imageContainer,
	.printed-ecologies .imageContainer {
		margin-top: 32px;
	}
	.caption {
		display: none;
	}
	.map {
		--article-width: 40vw;
		--max-width: 480px;
		height: 400vw;
		margin-left: 0;
		width: 100%;
	}
	.printed-ecologies {
		margin-left: calc(calc(min(var(--article-width), var(--max-width)) + 18px) * -1);
		padding: 18px 18px 0 0;
	}
	.women-in-whistler {
		margin-left: calc(calc(min(var(--article-width), var(--max-width)) + 18px) * -1);
		padding: 18px 18px 0 0;
		
	}
	.women-in-whistler .circleTitle,
	.printed-ecologies .circleTitle {
		text-align: left;
		left: 0;
	}
	.article {
		position: absolute;
		padding: 18px 0 0 18px;
		width: var(--article-width);
	}
	.circleTitle {
		padding-right: 0;
	}
	.over-london-at-night,
	.whistler-and-battersea {
		margin-left: -90px;
	}

	.over-london-at-night h1,
	.whistler-and-battersea h1 {
		margin-top: 10px;
	}
	.mapInner {
		height: 100%;
		position: relative;
		bottom: auto;
		width: 100vw;
	}
	.galleryImage {
		max-height: 460px;
	}

	
}
@media (small){

	.galleryImage {
		max-height: none;
	}
	.over-london-at-night,
	.whistler-and-battersea {
		margin-left: 0;
	}
	.over-london-at-night h1,
	.whistler-and-battersea h1 {
		margin-top: 0;
	}
	.the-surrounding-great-work  {
		display: grid;
		width: auto;
		grid-template-columns: var(--article-width) var(--article-width);
		max-width: 960px;
		grid-gap: 15px;
	}
	.caption {
		display: block;
	}
	.map {
		--article-width: 28vw;
		--max-width: 480px;
	}

}

@media 
(-webkit-min-device-pixel-ratio: 2), 
(min-resolution: 192dpi) { 
   @-moz-document url-prefix() {
		.map {
			max-height: 4096px;		
		}

		.map {
			--article-width: 20vw;
		}
		@media (xsm){
			.article h1 {
				font-size: 2.2vw;
			}
			.article h2 {
				font-size: 1.6vw;
			}
		}
		

	}
}