.display {
	position: fixed;
	z-index: 1;
	width: 100vh;
	transform: rotate(-90deg);
	transform-origin: 0 0;
	height: 30px;
	display: flex;
	align-items: center;
	padding: 0 10px;
	justify-content: space-between;
	color: var(--orange);
	top: 100vh;
	left: 0;
	right: 0;
	border-bottom: 1px solid var(--orange);
}