

@value xsm from './../Breakpoints.module.css';


.display {
	padding: 20px var(--padding) 20px var(--padding);
	margin-left: 30px;
	z-index: 2;
}


.display h1 {
	flex: 0 0 310px;
	margin-bottom: 0;
	
}

.display span {
	color: var(--orange);
}

.display p {
	font-size: min(4vw, 20px);

	margin-top: 6px;
	color: var(--dark);
	font-weight: 400;
}

.display img {
	width: 8px;
	margin-left: 4px;
	position: relative;
	top: 2px;
}


@media (xsm){
	.display h1 {
		font-size: min(6.4vw, 60px);
	}
	.display {
		padding: 15px var(--padding) 10px var(--padding);
		margin-left: 0;
		position: absolute;
		left: 10vw;
		top: 10vw;
	}

}