html {
	box-sizing: border-box;
	--padding: 20px;
	--dark: #302b14;
	--light: #f1ede4;
	--orange: #e27949;
	--tan: tan;
	--header-height: 70px;
	--selected: #cac7bb;
}

*, *:before, *:after {
	box-sizing: inherit;
}

a:link, a:visited, a:active, a:hover {

}



body {
	color: var(--dark);
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	line-height: 1.3;
	font-size: 14px;
	background: var(--light);
	-webkit-font-smoothing: antialiased;
	font-weight: 500;
	-moz-osx-font-smoothing: grayscale;

}



@font-face {
	font-family: 'Druk-Web';
	src: url('./fonts/Druk-Medium-Web.woff2') format('woff2'),
		url('./fonts/Druk-Medium-Web.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
}


@font-face {
	font-family: 'Druk-Text-Web';
	src: url('./fonts/DrukText-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
}


.tns-outer button {
	appearance: none;
	border: none;
	font-family: inherit;
	font-weight: inherit;
	font-size: inherit;
}

.tns-outer button[data-controls="prev"] {
	margin-right: 5px;

}

.tns-nav button {
	background: #bcb9ae;
	height: 3px;
	margin-right: 2px;
}
.tns-nav button.tns-nav-active {
	background: var(--orange);
}

.tns-nav {
	order: 1;

	display: flex;
	align-items: center;
	margin-right: 10px;
	height: 20px;
	padding-right: 80px;
	padding-left: 20px;
}

.tns-controls {
	order: 2;
	margin-bottom: 8px;
	z-index: 1;
	padding-left: 10px;
}

.tns-controls,
.tns-nav {
	position: absolute;
	top: -30px;
	background: var(--light);
}

@media only screen and (max-width: 1084px){
	.tns-controls,
	.tns-nav {
		position: relative;
		top: 0;
		background: transparent;
	}
	.tns-nav {
		padding: 0;
	}
}

.tns-controls button {	
	background: #bcb9ae;
	padding: 2px 6px 4px 6px;
	border-radius: 3px;
	color: var(--dark);
	font-size: 12px;
}

.tns-controls button:active {
	background: var(--selected);
	
}

.tns-outer {		
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-end;
}

.tns-ovh {
	order: 3;
	flex: 1 0 100%;
}

.mapboxgl-ctrl {
	display: none !important;
}

#root {
	overflow: hidden;
}

